.organizational-members-texts {
    >p:first-child {
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        font-size: 1.25rem;
        letter-spacing: 2px;
        @include lg {
            font-size: 2rem;
            letter-spacing: 2.1px;
        }
        @include xl {
            font-size: 2.25rem;
        }
        @include xl3 {
            font-size: 2.625rem;
            letter-spacing: 4.2px;    
        }
    }
    >p:nth-child(2) {
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        font-size: 0.9375rem;
        letter-spacing: 1.5px;
        @include md {
            font-size: 1rem;
        }
        @include xl {
            font-size: 1.25rem;
        }
        @include xxl {
            font-size: 1.5rem;
        }
        @include xl3 {
            font-size: 1.75rem;
            letter-spacing: 2.8px;    
        }
    }
}

.organizational-buttons-group {
    button {
        font-style: normal;
        // font-weight: 500;
        line-height: normal;
        color: white;
        border-radius: 0;
        font-size: 0.8125rem;
        @include xl {
            font-size: 1.25rem;
        }
        @include xl3 {
            font-size: 1.75rem;
        }
    }
}

.organizational-modal-overlay {
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
}

.organizational-modal-popup {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    // max-height: 70vh;
}

// .organizational-modal-body {
//     max-height: 70vh;
// }

.organizational-modal-person-img {
    width: 118px;
    height: 118px;
    @include lg {
        width: 200px;
        height: 200px;
    }
    @include xxl {
        width: 250px;
        height: 250px;
    }
}

.organizational-modal-person-name-zh {
    font-size: 2.1875rem;
    letter-spacing: 3.5px;
    @include lg {
        font-size: 3.75rem;
        letter-spacing: 6px;    
    }
}

.organizational-modal-person-name-en {
    font-weight: 500;
    font-size: 1.0625rem;
    letter-spacing: 0.85px;
    @include lg {
        letter-spacing: 1.6px;
        font-size: 2rem;    
    }
}

.organizational-modal-title {
    font-weight: 500;
    font-size: 0.9375rem;
    @include md {
        font-size: 1.5rem;
    }
    @include lg {
        font-size: 1.875rem;
    }
}

.organizational-modal-content-ul {
    font-weight: 350;
    // line-height: 32px;
    font-size: 0.875rem;
    @include lg {
        font-size: 1rem;
    }
    @include xxl {
        font-size: 1.25rem;
    }
    @include xl3 {
        font-size: 1.5rem;
    }
}

.organizational-modal-closeBtn {
    width: 24px;
    height: 24px;
    @include lg {
        width: 50px;
        height: 50px;    
    }
}