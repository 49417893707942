.transformation-institute-section {
    margin-top: 50px;
    @include lg {
        margin-top: 0;
    }
}

.transformation-institute-line-name-container {
    transform: translateY(-50%);
    @include lg {
        transform: translate(30px, -40%);
    }
    // @include xl35 {
    //     transform: translate(30px, -50%);
    // }
    // img {
    //     @include lg {
    //         width: 75%;
    //     }
    // }
}

.transformation-institute-person {
    width: 315px;
    height: 174px;
    @include md {
        width: 524px;
        height: 290px;
    }
    @include lg {
        width: 100%;
        height: unset;
    }
    // @include xl35 {
    //     width: 732px;
    //     height: 404px;
    // }
}

.transformation-institute-slash {
    width: 40%;
    transform: translateX(150%);
    @include md {
        width: 70%;
        transform: translate(0);
    }
    @include lg {
        width: 100%;
    }
}

.transformation-institute-person-name {
    text-wrap: nowrap;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    >p:first-child {
        font-size: 1.326rem;
        transform: translateY(3px);
        @include md {
            font-size: 1.75rem;
        }
        @include lg {
            font-size: 2rem;
            transform: translateX(-20px);
        }
        @include xl3 {
            font-size: 3.375rem;
            transform: translateX(0);
        }
    }
    >p:nth-child(2) {
        font-size: 0.75rem;
        @include md {
            font-size: 1rem;
        }
        @include lg {
            transform: translateY(-5px);
        }
        @include xl {
            font-size: 1.25rem;
        }
        @include xl3 {
            font-size: 1.75rem;
        }
    }
}

.transformation-institute-content {
    font-style: normal;
    // line-height: 32px;
    letter-spacing: 1.6px;
    @include xl {
        font-size: 1.25rem;
        letter-spacing: 1.3px;
    }
    @include xl3 {
        font-size: 1.625rem;
        letter-spacing: 2.6px;    
    }
    p:nth-child(odd) {
        font-weight: 500;
        text-wrap: nowrap;
    }
    p:nth-child(even) {
        font-weight: 300;
    }
}

.transformation-institute-learn-more {
    font-style: normal;
    // font-weight: 500;
    font-size: 0.8125rem;
    @include md {
        font-size: 1rem;
    }
    @include xl {
        font-size: 1.25rem;
    }
    @include xxl {
        font-size: 1.5rem;
    }
    @include xl3 {
        font-size: 1.75rem;
    }
}