.container {
    padding-right: 30px;
    padding-left: 30px;
    @include md {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media screen and (min-width: 1536px) {
    .container {
        max-width: 1480px;
    };
}

@media  screen and (min-width: 1900px) {
    .container {
        max-width: 1680px;
    };
}