.page {
    padding: 30px 0;
    min-height: calc(100vh - 73px);
    @include md {
        padding: 20px 0;
    }
    @include lg {
        padding: 50px 0;
        min-height: calc(100vh - 102px);
    }
}

@media screen and (min-width: 1536px) {
    .mt-xl3-0 {
        margin-top: 0px !important;
    }
    .mt-xl3-50px {
        margin-top: 50px !important;
    }
}
@media screen and (min-width: 1536px) {
    .mt-xl3-60px {
        margin-top: 60px !important;
    }
}
@media screen and (min-width: 1900px) {
    .mt-xl4-75px {
        margin-top: 75px !important;
    }
}

.pageTitleEn {
    font-size: 1.25rem;
    font-weight: 400; // 500
    letter-spacing: 1px;
    @include md {
        font-size: 1.5rem;
    }
    @include lg {
        font-size: 2rem;
        letter-spacing: 2.4px;
    }
    @include xl {
        font-size: 2.25rem;
    }
    @include xxl {
        font-size: 2.5rem;
    }
    @include xl3 {
        font-size: 2.75rem;
    }
    @include xl35 {
        font-size: 3rem;
    }
}

.pageTitleZh {
    font-size: 1rem;
    font-weight: 300;
    @include md {
        font-size: 1.25rem;
    }
    @include lg {
        font-size: 1.5rem;
    }
    @include xxl {
        font-size: 1.75rem;
    }
}

.label-text {
    color: #FFF;
    font-style: normal;
    // font-weight: 700;
    line-height: normal;
    font-size: 1rem;
    letter-spacing: 1.688px;
    @include md {
        font-size: 1.25rem;
    }
    @include lg {
        font-size: 1.5rem;
        letter-spacing: 3px;
    }
    @include xl {
        font-size: 1.75rem;
    }
    @include xxl {
        font-size: 1.875rem;
    }
}


.invisible {
    opacity: 0;
    visibility: hidden;
}

.visible {
    opacity: 1;
    visibility: visible;
}

/* 確保動畫元素的過渡效果平滑 */
.animate__animated {
    transition: opacity 0.3s, visibility 0.3s;
}

.hover-bg-transparent {
    &:hover {
        background: transparent !important;
    }
}

.hover-text-white {
    &:hover {
        color: #FFF !important;
    }
}

.hover-border-white {
    &:hover {
        border-color: #FFF !important;
    }
}