.header-navbar {
    @include md {
        height: 90px;
    }
    @include lg {
        height: unset;
    }
}

.logo-link {
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    text-indent: 101%;
    overflow: hidden;
    white-space: nowrap;
    background-image: url("../../images/header/logo.png");
    height: 22px;
    @include sm {
        height: 42.5px;
    }
    @include lg {
        width: 330px;
    }
}

.projects-subCategory-ul > li {
    padding: 0 !important;
}

.show-border::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background-color: #695C55;
    animation: revealBorder 0.3s ease-in-out forwards;
}

.hide-border::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background-color: black;
    animation: revealBorder 0.3s ease-in-out backwards;
}

.hamburgerMenuBtn {
    @extend .p-0;
    height: 16px;
    @include sm {
        height: 24px;
    }
    @include md {
        height: 28px;
    }
}

.hamburgerMenuLine {
    height: 2px;
    width: 22px;
    background: var(--#{$prefix}secondary);
    @include sm {
        width: 30px;
        height: 2px;
    }
    @include md {
        width: 40px;
        // height: 2px;
    }
}

.offcanvas {
    width: 100% !important;
    // max-width: 375px !important;
}

.header-ul {
    font-size: 0.9rem;
    @include xxl {
        font-size: 1rem;
    }
    @include xl3 {
        font-size: 1.1875rem;
    }

    >li {
        border-bottom: 4px solid transparent;
        >a {
            color: black;
            @include xl {
                padding: 0 8px;
            }
            @include xxl {
                padding: 0 16px;
            }
            @include xl3 {
                padding: 0 24px;
            }        
        }
        &:hover {
            border-bottom: 4px solid #DA4724;
            >a {
                color: #129A9D;
            }
        }
    }
} 